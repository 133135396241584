/* ==========================================================================
   # Name: _c-cta-banner
   # Author: sam
   ========================================================================== */

.c-cta-banner {
  @if $enable-rounded {
    border-radius: $border-radius-sm;
  }
  padding: map_get($spacers, 4) map_get($spacers, 3);

  &__intro {
    width: 100%;
    text-align: center;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: map_get($spacers, 4);

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      align-items: start;
    }

    & > * {
      &:not(:first-child) {
        margin-top: map_get($spacers, 3);

        @include media-breakpoint-up(sm) {
          margin-top: 0;
          margin-left: map_get($spacers, 2);
        }
      }
    }
  }

  &_right {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: map_get($spacers, 4) map_get($spacers, 5);

      .c-cta-banner__intro {
        text-align: left;
      }

      .c-cta-banner__wrapper {
        margin-top: 0;
        min-width: 33%;
        justify-content: flex-end;
      }
    }
  }
}
