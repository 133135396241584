@mixin rainbow($position: absolute, $zIndex: 0) {
  position: relative;

  &:before {
    display: block;
    content: '';
    position: $position;
    left: 0;
    bottom: 0;
    z-index: $zIndex;
    height: 6px;
    width: 100%;

    @include media-breakpoint-up('md') {
      height: 8px;
    }

    @include media-breakpoint-up('lg') {
      height: 10px;
    }

    background:
      linear-gradient(
          to right,
          $blue-dark 0%,
          $blue-dark 20%,
          $blue-light 20%,
          $blue-light 40%,
          $yellow 40%,
          $yellow 60%,
          $green 60%,
          $green 80%,
          $orange 80%,
          $orange 100%
      );
  }
}
