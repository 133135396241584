a {
  transition: $transition-base;
  &:hover {
    span {
      border-bottom: 0;
    }
  }
}

a.offset-anchor {
  display: block;
  position: relative;
  visibility: hidden;
  top: -100px;
  @include media-breakpoint-up('lg') {
    top: -170px;
  }
}
