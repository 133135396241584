/* ==========================================================================
   # Name: _c-text
   # Date: 18 aug. 2020
   # Author: sam
   ========================================================================== */

.c-text {
    font-size: $font-size-base;

    p:last-child {
        margin-bottom: 0;
    }

    &__ctas {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: map_get($spacers, 4);

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            align-items: start;
        }

        & > * {
            &:not(:first-child) {
                margin-top: map_get($spacers, 3);

                @include media-breakpoint-up(sm) {
                    margin-top: 0;
                    margin-left: map_get($spacers, 2);
                }
            }
        }
    }
}