/* ==========================================================================
   # Name: _cta
   # Date: 18 aug. 2020
   # Author: sam
   ========================================================================== */

.c-cta {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  &__micro {
    font-size: $font-size-sm;
    color: $gray-600;
    margin-top: map_get($spacers, 1);
  }
}

