.c-hero {

  //// Fix for ultra wide screens
  //@media screen and (min-width: 1921px) {
  //  overflow: hidden;
  //}

  &__inner {
    background-repeat: no-repeat;
    background-size: 100% auto;
    @include media-breakpoint-down('xs') {
      background-image: url(../assets/images/banner-bg-576w.jpg);
    }
    @include media-breakpoint-only('sm') {
      background-image: url(../assets/images/banner-bg-768w.jpg);
    }
    @include media-breakpoint-up('md') {
      background-image: url(../assets/images/banner-bg-1120w.jpg);
      background-position: right top;
      background-size: auto 100%;
    }

    //// Fix for ultra wide screens
    //@media screen and (min-width: 1921px) {
    //  width: 1920px;
    //  margin: 0 auto;
    //  box-shadow: 0 0 70px rgba(0,0,0,0.1);
    //}

  }

  &__placeholder {
    &:before {
      content: '';
      display: block;
      padding-top: 66.25%;
      @include media-breakpoint-up('sm') {
        padding-top: 60%;
      }
    }
  }

  &__title {
    @include media-breakpoint-up('sm') {
      font-size: $display3-size * 1.3;
    }
    @include media-breakpoint-up('md') {
      font-size: $display3-size * 1.5;
    }
    @include media-breakpoint-up('lg') {
      font-size: $display3-size * 1.5;
    }
    @include media-breakpoint-up('xl') {
      font-size: $display3-size * 1.8;
    }
  }

  &__content {
    font-weight: $font-weight-normal;
    border-left: 2px solid $orange;
    margin-left: 2px;
    padding-left: 18px;

    @include media-breakpoint-up('lg') {
      font-size: $font-size-base * 1.1;
    }
  }

  &__wrapper {
    @include media-breakpoint-up('md') {
      min-height: 564px;
    }
    @include media-breakpoint-up('xl') {
      min-height: 625px;
    }
  }

  .page-template-page-vacancies &__inner {
    @include media-breakpoint-down('xs') {
      background-image: url(../assets/images/banner-vacancies-bg-576w.jpg);
    }
    @include media-breakpoint-only('sm') {
      background-image: url(../assets/images/banner-vacancies-bg-768w.jpg);
    }
    @include media-breakpoint-up('md') {
      background-image: url(../assets/images/banner-vacancies-bg-1120w.jpg);
    }
  }

  .page-template-page-vacancies &__wrapper {
    @include media-breakpoint-up('md') {
      min-height: 370px;
    }
    @include media-breakpoint-up('xl') {
      min-height: 431px;
    }
  }

}
