.c-icon {
  &__about {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;

    @include media-breakpoint-down('sm') {
      height: 100px;
      img {
        height: 80px;
        width: auto;
      }
    }
  }

  &_sm {
    transform-origin: left center;
    transform: scale(0.75);
  }

  &_lg {
    transform-origin: left center;
    transform: scale(1.15);
  }

}
