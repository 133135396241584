/* ==========================================================================
   # Name: _c-image
   # Author: sam
   ========================================================================== */

.c-image {
    @if $enable-rounded {
    border-radius: $border-radius-sm;
  }
  width: 100%;
  display: block;
}
