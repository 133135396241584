.swiper {
  &-slide {
    &-side-by-side {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up('md') {
        flex-direction: row;
      }

      & > div:first-child {
        border-radius: $border-radius-sm;

        @include media-breakpoint-up('md') {
          width: 50%;
        }

        @include media-breakpoint-up('lg') {
          width: calc(50% - 10px);
          margin-right: 10px;
        }
      }

      & > div:last-child {
        flex-grow: 1;

        @include media-breakpoint-up('md') {
          width: 50%;
        }

        @include media-breakpoint-up('lg') {
          width: calc(50% - 10px);
          margin-left: 10px;
        }
      }
    }

    &__image {
      min-height: $spacer * 12;

      @include media-breakpoint-up('lg') {
        min-height: $spacer * 22;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding: ($spacer * 2) 0 ($spacer * 2) $spacer;

      @include media-breakpoint-up('md') {
        padding: ($spacer * 2) ($spacer * 2);
      }

      @include media-breakpoint-up('lg') {
        padding: ($spacer * 2) ($spacer * 2);
      }
    }
  }

  &-pagination {
    &-bullet {
      background-color: rgba($blue-light, 0.2) !important;
      width: 8px !important;
      height: 8px !important;

      &:focus {
        outline: 2px solid $primary;
      }

      &.swiper-pagination-bullet-active {
        background-color: $blue-light !important;
      }
    }
  }

  &-button {
    opacity: 1;

    &-disabled {
      opacity: 0.2 !important;
    }
  }

  .carousel-control-prev {
    left: -85px
  }

  .carousel-control-next {
    right: -85px
  }

  .carousel-control-next,
  .carousel-control-prev {
    display: none;
    @include media-breakpoint-up('xl') {
      display: flex;
    }
  }
}

.swiper-nav {
  &__item {
    &:hover {
      color: $teal;
    }

    &.active {
      border-color: $teal !important;
      font-weight: $font-weight-bold;
    }
  }
}
