.c-google-maps {
  width: 100%;
  height: 400px;
  position: relative;

  @include media-breakpoint-up(md) {
    height: 550px;
  }

  @include media-breakpoint-up(md) {
    height: 640px;
  }


  // Fixes potential theme css conflict.
  img {
    border-radius: 5px;
    max-width: inherit !important;
  }

  .gm-style-iw-c,
  .gm-style-iw-d {
    max-height: 260px !important;
  }


  @include media-breakpoint-down(sm) {
    &__thumb {
      height: 60px;
      width: 100%;
      object-fit: cover;
      margin-bottom: 0.5rem;
    }

    &__content {
      h3 {
        font-size: 1rem !important;
      }

      p,
      a {
        font-size: 0.9rem !important;
      }
    }
  }

  &__wrapper {
    position: relative;

    .c-google-maps {
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  &__cookie-banner {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
    margin: 1rem;

    &__message {
      background-color: white;
      color: black;
      padding: 2rem;
      border-radius: 5px;
    }
  }
}
