.c-btn {
  @if $enable-rounded {
    border-radius: $border-radius;
  }
  @if $enable-shadows {
     box-shadow: 0 2px 4px rgba(0,0,0,0.25);
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 0;

  //padding: ;

  &:hover {
    text-decoration: none;

    @if $enable-shadows {
      box-shadow: 0 3px 5px rgba(0,0,0,0.35);
    }

    span {
      border: none;
    }
  }

  // If there are 2 items in a btn. A arrow for example
  & > * {
    &:nth-child(2) {
      margin-left: 10px;
    }
  }

  & > svg {
    max-width: 20px;
    max-height: 20px;
    fill: $white;

    // Often the lineheight wont work well with vertical center svg
    margin-top: 2px;
  }

  &-primary {
    border: 1px solid $primary;
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: lighten($primary, 10%);
      border-color: lighten($primary, 10%);
      color: $white;
    }
  }
  &-secondary {
    border: 1px solid $secondary;
    background-color: $secondary;
    color: $white;

    & > svg {
      fill: $secondary;
    }

    &:hover {
      background-color: lighten($secondary, 10%);
      border-color: lighten($secondary, 10%);
      color: $white;
    }
  }

  &-outline {
    border: 1px solid $primary;
    background: transparent;
    color: $primary;

    & > svg {
      fill: $primary;
    }

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }

  &-link {
    border: none;
    background: transparent;
    color: $primary;

    box-shadow: none !important;

    & > svg {
      fill: $primary;
    }

    &:hover {
      background-color: transparent;
      color: $primary;
      text-decoration: underline;
    }
  }

  &-white {
    border: 1px solid $white;
    background-color: $white;
    color: $primary !important;

    & > svg {
      fill: $primary;
    }

    &:hover {
      background-color: darken($white, 10%);
      border-color: darken($white, 10%);
    }
  }
}
