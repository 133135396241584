/* ==========================================================================
   # Name: _c-side-by-side
   # Author: sam
   ========================================================================== */

.c-side-by-side {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg){
    flex-direction: row;
  }

  &__block {
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: 50%;
    }

    &:first-child {
      margin-bottom: map_get($spacers, 3);

      @include media-breakpoint-up(lg) {
        margin-right: map_get($spacers, 4);
        margin-bottom: 0;
      }
    }
  }

  &__image {
      @if $enable-rounded {
        border-radius: $border-radius-sm;
    }
    width: 100%;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(sm) {
      align-items: start;
      justify-content: start;
    }

    @include media-breakpoint-up(lg) {
      margin-top: map_get($spacers, 3);
    }

    & > * {
      width: 100%;
    }
  }

  &__ctas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
      align-items: start;
      justify-content: start;
    }

    & > * {
      &:not(:first-child) {
        margin-top: map_get($spacers, 3);

        @include media-breakpoint-up(sm) {
          margin-top: 0;
          margin-left: map_get($spacers, 2);
        }
      }
    }
  }
}
