.blockquote {
  font-weight: $font-weight-normal;
  font-size: $font-size-lg;
  line-height: $line-height-base * 1;
  border-left: 2px solid $orange;
  margin-left: 2px;
  padding-left: 18px;

  @include media-breakpoint-down('sm') {
    font-size: $font-size-lg * 0.83;
  }

  @include media-breakpoint-up('lg') {
    padding-left: 28px;
  }
}
