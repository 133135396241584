.c-desktop-menu {
  .nav-link {
    color: $secondary;
    font-weight: $font-weight-bold;
    text-decoration: none;
    &:hover {
      color: $primary;
    }
  }

  &__top {
    padding: 0 0.4rem !important;
    a {
      border-right: 1px solid #bac9d2;
      padding: 0 0.8rem !important;

      &:last-child {
        border-right: none;
      }
    }
  }
}
