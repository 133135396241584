/*
  Project: korian
  Author: Thomas
 */


.rounded-sm {
  border-radius: $border-radius-sm !important;
}

.rounded-bottom-sm {
  border-radius: 0 0 $border-radius-sm $border-radius-sm !important;
}

.rounded-lg {
  border-radius: $border-radius-lg !important;
}
