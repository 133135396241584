/*
  Project: korian
  Author: Thomas
 */

.c-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .c-header-mobile & {
    //padding-top: 4px;
    svg {
      height: 24px;
      width: auto;
    }
  }
}
