.breadcrumb-back {
  position: relative;
  padding: 6px 16px 5px 38px;
  background: transparent;
  color: $primary;
  border-radius: 20px;
  cursor: pointer;
  transition: $transition-base;
  font-size: $font-size-sm;
  border: 1px solid $gray-400;
  &:focus {
    outline: none;
  }

  &:hover {
    border: 1px solid $primary;
  }

  &:before {
    content: '';
    width: 7px;
    height: 12px;
    display: block;
    position: absolute;
    left: 13px;
    top: 50%;
    background: url(../assets/images/chevron-right.svg) no-repeat;
    background-size: 100%;
    transition: transform .25s ease-in-out;
    transform: translateY(-50%) rotate(180deg);
  }
}
