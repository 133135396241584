.faq {
  outline: none!important;

  .caret-down::after {
    color: $blue-light;
  }

  &__title {
    border-top: 1px solid $gray-300;
    padding: 0.85rem 0;
  }

  &:last-child {
    border-bottom: 1px solid $gray-300;
  }
}
