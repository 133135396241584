/* ==========================================================================
   # Name: _c-faq
   # Author: sam
   ========================================================================== */

.c-faq {
  &__intro {
    margin-bottom: map-get($spacers, 3);

    @include media-breakpoint-up('lg') {
      margin-bottom: map-get($spacers, 5);
    }
  }

  &__text_below {
    margin-top: map-get($spacers, 5);

    @include media-breakpoint-up('lg') {
      margin-top: map-get($spacers, 7);
    }
  }

  &__question {
    text-align: left;
    font-weight: $font-weight-bold;
  }

  &__answer {
    text-align: left;
    font-weight: $font-weight-bold;
  }
}

