.c-bg-logo {
  background: url(../assets/images/bg-transparent-logo.svg) no-repeat;
  background-position: left 20%;
  background-size: auto 80%;

  @include media-breakpoint-up('lg') {
    background-size: auto 95%;
  }

  @include media-breakpoint-up('lg') {
    &__whiter {
      background: url(../assets/images/bg-transparent-logo-whiter.svg) no-repeat;
      background-position: left 40%;
      background-size: auto 80%;
    }
  }

  &__whiter_b {
    background: url(../assets/images/bg-transparent-logo-whiter.svg) no-repeat;
    background-position: left 40%;
    background-size: 65% auto;
    @include media-breakpoint-up('sm') {
      background-size: 55% auto;
    }
    @include media-breakpoint-up('md') {
      background-position: left 50px;
      background-size: 500px auto;
    }
    @include media-breakpoint-up('xl') {
      background-size: 500px auto;
    }
  }
}
