@font-face {
  font-family: 'CentGothWGL';
  src: url('../assets/fonts/CentGothWGL/CentGothWGL/font.woff2') format('woff2'),
  url('../assets/fonts/CentGothWGL/CentGothWGL/font.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'CentGothWGL';
  src: url('../assets/fonts/CentGothWGL/CentGothWGL-Bold/font.woff2') format('woff2'),
  url('../assets/fonts/CentGothWGL/CentGothWGL-Bold/font.woff') format('woff');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'CentGothWGL';
  src: url('../assets/fonts/CentGothWGL/CentGothWGL-Italic/font.woff2') format('woff2'),
  url('../assets/fonts/CentGothWGL/CentGothWGL-Italic/font.woff') format('woff');
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: 'CentGothWGL';
  src: url('../assets/fonts/CentGothWGL/CentGothWGL-BdIt/font.woff2') format('woff2'),
  url('../assets/fonts/CentGothWGL/CentGothWGL-BdIt/font.woff') format('woff');
  font-style: italic;
  font-weight: bold;
}
