.u-content-border__bottom,
section.component h2 {
  position: relative;
  padding-bottom: $spacer * 1.2;
  margin-bottom: $spacer * 1.6;
  text-align: center;
  &:after {
    content: '';
    display: block;
    position: absolute;
    background: $blue-light;
    height: 4px;
    width: 74px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.u-content-border__small_left {
  @include media-breakpoint-up('lg'){
    border-left: 1px solid $gray-200;
  }
}
