/*
  Project: korian
  Author: Thomas
 */

h2, .h2 {
  @include media-breakpoint-up('lg') {
    font-size: $h2-font-size * 1.3;
  }
}

h3, .h3 {
  font-family: $font-family-centgoth;
  font-weight: $font-weight-bold;
}

h4, .h4 {
  font-family: $font-family-centgoth;
  font-weight: $font-weight-bold;
}

.normal {
  font-family: $font-family-centgoth;
}

.smaller {
  font-size: 80%;
}

.font-family-sans {
  font-family: $font-family-sans;
}

.font-family-sans-serif {
  font-family: $font-family-sans-serif;
}

.text-decoration-none {
  text-decoration: none;
}
