/*
  Project: korian
  Author: Thomas
 */

$delay: 50ms;

.c-mobile-menu {
  @include rainbow(absolute, 300);
  transition: $transition-base;
  background: $white;
  position: fixed;
  right: 0;
  left: 0;
  min-height: calc(100% - 62px);
  z-index: 101;
  display: flex;
  top: 62px;

  .nav-link {
    color: $secondary;
    padding-left: 2px;
    padding-right: 2px;
    text-decoration: none;
  }


  &_container {
    position: absolute;
    overflow: auto;
    height: calc(100% - 100px);
    width: calc(100% - 30px);
  }

  // .menu-item:not(.btn-parent) .nav-link {
  //   transition: $transition-base;
  //   //color: $gray-dark !important;
  //   font-family: $font-family-sans;
  //   font-size: $font-size-lg;
  //   padding: 0.75rem;
  // }

  &_item {
    opacity: 0;
    margin: 0;
    transform: translateX(-10px);
    transition: $transition-base;
    border-bottom: 1px solid $gray-200;
    color: $secondary;
  }

  @for $i from 1 through 5 {
    &_item:nth-child(#{$i}) {
      transition-delay: $i * $delay;
    }
  }

  &_button {
    opacity: 0;
    transition: $transition-base;
    transition-delay: $delay * 7;
  }

  &_search {
    opacity: 0;
    transform: translateX(10px);
    transition: $transition-base;
    transition-delay: $delay * 8;
  }

  &_sub {
    position: relative;
    &:after {
      background: url(../assets/images/icon-arrow.svg) no-repeat;
      background-size: 100% auto;
      content: '';
      top: 1.4rem;
      right: 0.75rem;
      display: block;
      position: absolute;
      width: 15px;
      height: 11px;
      margin-left: 7px;
      transition: $transition-base;
    }

    &.collapsed {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  .c-secondary-menu_mobile {
    opacity: 0;
    transform: translateX(10px);
    transition-delay: $delay * 9;
  }

  &.show {
    .c-mobile-menu_item ,
    .c-mobile-menu_button ,
    .c-mobile-menu_search ,
    .c-secondary-menu_mobile {
      opacity: 1;
      transform: translateX(0);
    }
  }

  // The container for submenu relative is body
  &_dropdown {
    transition: $transition-base;
    //visibility: hidden;
    //opacity: 0;
    width: 100%;
    height: auto;

    .nav-link {
      padding: 0.5rem 0.75rem;
      font-size: $font-size-base;
    }
  }
}
