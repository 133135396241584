/*
  Project: korian
  Author: Thomas
 */

*[class*="btn-outline-"] {
  border-width: 1px;
}

.btn {
  text-decoration: none;
  &-icon {
    svg {
      transform: translate(0, 3px);
    }
    &:hover {
      svg {
        animation: shake 1s;
      }
    }
  }

  &:hover {
    text-decoration: none;
    & > * {
      text-decoration: none;
      border-bottom: 0;
    }
  }

  @include media-breakpoint-down('xs') {
    &-xs-block {
      display: block;
      width: 100%;
      text-align: center;
    }
  }

  &-white {
    color: $secondary;
    &:hover {
      color: $secondary !important;
      background: transparentize($white, 0.2) !important;
      border-color: transparentize($white, 0.2) !important;
    }
  }

  &-primary {
    &:hover {
      color: $white !important;
      background: lighten($primary, 8%);
      border-color: lighten($primary, 8%);
    }
  }

  &-secondary {
    &:hover {
      color: $white !important;
      background: lighten($secondary, 8%);
      border-color: lighten($secondary, 8%);
    }
  }


  &-white {
    &:hover {
      color: $black;
      background: $gray-200 !important;
      border-color: $gray-200 !important;
    }
  }

  &-outline-primary {
    color: $primary;
    &:hover {
      color: $primary !important;
      background: transparentize($primary, 0.9);
    }
  }

  &-outline-secondary {
    color: $secondary;
    &:hover {
      color: $secondary !important;
      background: transparentize($secondary, 0.9);
    }
  }

}

.btn-wrapper {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down('md') {
    display: inline-flex;
  }

  .btn-micro {
    text-align: center;
    margin-top: $spacer * 0.5;
    font-size: $font-size-sm;
    color: $secondary;
  }
}

