/* ==========================================================================
   # Name: _c-features
   # Author: sam
   ========================================================================== */

.c-features {
    &__intro {
        margin-bottom: map-get($spacers, 3);

        @include media-breakpoint-up('lg') {
            margin-bottom: map-get($spacers, 5);
        }
    }

    &__item {

        margin: map-get($spacers, 5) 0;

        &:not(:last-child) {
            margin-bottom: map-get($spacers, 4);

            @include media-breakpoint-up('md') {
                margin-bottom: map-get($spacers, 3);
            }
        }

        &-title {
            font-size: $font-size-lg;
        }

        &-left {
            display: flex;
            flex-direction: row;

            @include media-breakpoint-up('md') {
                &:nth-child(1) {
                    order: 1;
                }

                &:nth-child(2) {
                    order: 3;
                }

                &:nth-child(3) {
                    order: 2;
                }

                &:nth-child(4) {
                    order: 4;
                }
            }
            .c-features__item-group {
                padding-right: map-get($spacers, 1);

                @include media-breakpoint-up('lg') {
                    padding-right: map-get($spacers, 4);
                }
            }

            .c-features__item-icon {
                padding-left: map-get($spacers, 1);

                @include media-breakpoint-up('lg') {
                    padding-left: map-get($spacers, 4);
                }

                @include media-breakpoint-up('md') {
                    padding-left: 0;
                }

                margin-right: map-get($spacers, 3);
            }
        }

        &-center {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .c-features__item-group {
                text-align: center;
                margin-top: map_get($spacers, 2);
                padding: 0 map-get($spacers, 2);

                @include media-breakpoint-up('md') {
                    padding: 0 map-get($spacers, 4);

                }
            }
        }



        &-content {
            margin-top: map-get($spacers, 2);
        }
    }
}
